function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const startBot = (botMessageFeed, botIsPersonA) => {
    // filter the feed from other user
    botMessageFeed = botMessageFeed.filter(feed => feed.senderIsA !== botIsPersonA)
    return (messages) => getBotAnswer(botMessageFeed, messages);
}

const wordMatch = (wordA, wordB) => {
    let match = 0;
    let previousMatch = 0;
    for (let i = 0; i < wordA.length; i++) {
        if (wordA[i] === wordB[i]) {
            match ++;
            if (previousMatch) {
                match+= previousMatch
            }
            previousMatch++;
        } else {
            match--;
            previousMatch = 0;
        }
    }

    const maxLength = wordB.length > wordA.length ? wordB.length : wordA.length

    const matchPercentage = 100 / maxLength * match;

    return matchPercentage
}

const getBotAnswer = (botMessageFeed, messages) => {
    return new Promise(resolve => {
        let bestMatchPoints = -Infinity;
        let bestMatch = null;

        botMessageFeed.forEach(botFeed => {
            let questionMatch = 0;

            botFeed.questions.forEach(question => {
                messages.forEach(message => {
                    questionMatch += wordMatch(message, question)
                })
            });

            if (questionMatch > bestMatchPoints) {
                bestMatchPoints = questionMatch;
                bestMatch = botFeed;
            }

        });
        
        let selectAmount = messages.length + getRandomInt(-2, 2)

        if (selectAmount <= 0) {
            selectAmount = messages.length;
        }

        if (selectAmount > bestMatch.answers.length) {
            selectAmount = bestMatch.answers.length
        }

        const res = []

        bestMatch.answers.slice(bestMatch.answers.length - selectAmount - 1).forEach(answer => {
            // console.log('<jildert>: ' + answer)
            res.push(answer)
            selectAmount--;
            if (selectAmount === 0) {
                return;
            }
        })
        resolve(res);
    })
}

module.exports = startBot;
