import React, { useRef, useState } from 'react'

import Button from '@material-ui/core/Button';
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

const StyledInput = styled.input`
    display: none;
`

const onFileUpload = async (file, onUpload) => {
    var fileReader = new FileReader();
    fileReader.onload = function(fileLoadedEvent){
        var textFromFileLoaded = fileLoadedEvent.target.result;
        onUpload(textFromFileLoaded)
    };
  
    fileReader.readAsText(file, "UTF-8");
}

const UploadChat = ({ onUpload }) => {

    const [file, setFile] = useState();
    const inputRef = useRef();

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Typography variant="h6" component="h6">Upload chat and create bot.</Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={file ? 6 : 12}>
            <Button variant="contained" onClick={() => inputRef.current.click()}>
                Select file
            </Button>
        </Grid>
        {
            file && <Grid item xs={6}>
                <Typography style={{ marginTop: 6 }}>{file.name}</Typography>
            </Grid>
        }
        <Grid item xs={12}>
            <StyledInput
                ref={inputRef}
                type="file"
                onChange={event => setFile(event.target.files[0])}
            /> 
            {
            file && <Button variant="contained" color="primary" onClick={() => onFileUpload(file, onUpload)}> 
                Use chat to create bot
            </Button>
            }
        </Grid>
    </Grid>
}

export default UploadChat;
