import styled from 'styled-components'

export default styled.div`
    position: absolute;
    top: 24px;
    left: 12px;
    padding: 12px;
    right: 12px;
    height: 40%;
    background-color: #2a2f32;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    color: white;
    max-width: 1200px;
    margin: 0 auto;
`