import './App.css';
import { useState } from 'react';
import UploadChat from './components/upload'
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';  

import Paper from './components/paper'
import Bot from './components/bot'
import ChatInterface from './components/chat-interface'

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

function App() {
  const [messageFeed, setMessageFeed] = useState();
  const [bot, setBot] = useState();

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <div className="background">
        </div>

        {
          !bot && <Paper>
            {!messageFeed && <UploadChat onUpload={setMessageFeed} />}
            {messageFeed &&  <Bot messageFeed={messageFeed} onComplete={setBot}/>}
          </Paper>
        }

        {bot && messageFeed && <ChatInterface bot={bot} botName={bot.botName} username={bot.username} />}

      </div>
    </ThemeProvider>
  );
}

export default App;
