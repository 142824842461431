const ignoreMessages = ['‎afbeelding weggelaten', '‎video weggelaten']

const parseFile = (lines) => {
    let personA = null;
    let result = '';
    const names = [];

    lines.split('\n').forEach(line => {

        // const date = line.substring(1, line.indexOf(']'))

        if (line.indexOf(']') !== -1 && line[0] === ('[')) {
            const index1 = line.indexOf(']'); // end of date
            const index2 = line.substring(index1).indexOf(': '); // end of name

            const name = line.substring(index1 + 2, index1 + index2);
            const message = line.substring(index1 + index2 + 2).trim(); // begin of message

            if (!ignoreMessages.includes(message)) {
                if (!personA) {
                    personA = name;
                }
                const isPersonA = name === personA; 

                if (!names.find(n => n.name === name)) {
                    names.push({
                        name,
                        isPersonA
                    })
                }

                result += `<${isPersonA ? 'A' : 'B'}> ${message}\n`;
            }
        }
    });

    return { result, names };
}


module.exports = parseFile;