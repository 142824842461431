import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';

const ChatWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

const InputWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`

const StyledTextField = styled(TextField)`
    width: 100%;
`

const MessagesWrapper = styled.div`
    position: absolute;
    bottom: 56px;
    top: 0;
    width: 100%;
    overflow-y: auto;
`

const MessageWrapper = styled.div`
    background-color: #262d31;
    margin: 12px;
    color: white;
    border-radius: 6px;
    width: fit-content;
    max-width: 80%;

    margin-left: ${({ isBot }) => {
        return !isBot && 'auto';
    }};

`

const Name = styled.span`
    display: block;
    font-size: 14px;
    padding: 12px;

    color: ${({ isBot }) => {
        return isBot ? 'aquamarine' : '#f56868';
    }};
`

const Message = styled.span`
    display: block;
    font-size: 14px;
    padding: 12px;
    padding-top: 0;
    word-wrap: break-word;
`

const NewLineBreak = styled.div`
    width: 100%;
`

const StyledIcon = styled(SendIcon)`
    position: absolute;
    bottom: 16px;
    right: 10px;
    color: #96bfe3;
`

const ChatInterface = ({ bot, botName, username }) => {

    const textFieldRef = useRef()
    const [message, setMessage] = useState('');
    const [messageFeed, setMessageFeed] = useState([])
    const MessagesWrapperRef = useRef();

    useEffect(() => {
        textFieldRef.current.focus()
    }, [])


    const onKeyPress = (evt) => {
        if (evt.key === "Enter") {
            console.log('SEND: ' + message)
            setMessageFeed(prev => [...prev, {message, isBot: false}])
            setMessage('')

            setTimeout(() => {
                bot.getMessage([message]).then(responses => {
                    responses = responses.map(msg => ({ message: msg, isBot: true }))
                    setMessageFeed(prev => [...prev, ...responses])
                })
            }, 750)

            MessagesWrapperRef.current.focus()
        }
    }

    useEffect(() => {
        const element = MessagesWrapperRef.current;
        element.scrollTop = element.scrollHeight;
    }, [messageFeed])

    return <ChatWrapper>
        <MessagesWrapper ref={MessagesWrapperRef}>
            {
                messageFeed.map((msg, i) => {
                    return <NewLineBreak key={i}>
                        <MessageWrapper isBot={msg.isBot}>
                            <Name isBot={msg.isBot}>{msg.isBot ? botName : username}</Name>
                            <Message>{msg.message}</Message>
                        </MessageWrapper>
                    </NewLineBreak>
                })
            }
        </MessagesWrapper>
        <InputWrapper>
            <StyledTextField
                autoFocus ref={textFieldRef}
                variant="filled"
                color="primary"
                onKeyPress={onKeyPress}
                onChange={e => setMessage(e.target.value)}
                value={message}
            />
        </InputWrapper>
        <StyledIcon onClick={() => onKeyPress({ key: "Enter"})} />
    </ChatWrapper>
}

export default ChatInterface;
