const chatParser = require('./chat-parser');
const botLoader = require('./bot-loader');
const bot = require('./bot');

const parseFile = (messagesText) => {
    const { result, names } = chatParser(messagesText);
    return { result, names };
}

const loadBot = (chatParsed, botIsPersonA) => {
    const messageFeed = botLoader(chatParsed);
    return bot(messageFeed, botIsPersonA);
}

module.exports = {loadBot, parseFile};
