import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Progress from './progress'

import {loadBot, parseFile} from '../bot'
import { Divider } from '@material-ui/core';

const Bot = ({ messageFeed, onComplete }) => {

    const [parsedResult, setParsedResult] = useState('none')

    const [progessValue, setProgress] = useState(0);
    const [botIsPersonA, setPerson] = useState(null);

    useEffect(() => {
        const { result, names } = parseFile(messageFeed)
        setParsedResult({ result, names })
    }, [messageFeed])

    useEffect(() => {
        if (botIsPersonA !== null) {
            const getMessage = loadBot(parsedResult.result, botIsPersonA);
            const botObject = { getMessage };

            const personA = parsedResult.names.find(n => n.isPersonA).name
            const personB = parsedResult.names.find(n => !n.isPersonA).name

            botObject.botName = botIsPersonA ? personA : personB
            botObject.username = botIsPersonA ? personB : personA

            onComplete(botObject)
        }
    }, [botIsPersonA, parsedResult, onComplete])


    useEffect(() => {
        setInterval(function() {
            setProgress(prev => {
                if (prev >= 100) {
                    clearInterval(this)
                    return 100;
                } else {
                    return Math.min(prev + 5, 100)
                }
            })
        }, 25)
    }, [])

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Typography variant="h6" component="h6">{progessValue >= 100 ? "Done creating bot." : "Creating your bot.." }</Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={12}>
            <Progress value={progessValue} />
        </Grid>
        
        {
            progessValue >= 100 && parsedResult && parsedResult.names.length >= 2 && <>
                 <Grid item xs={12}>
                    <Typography variant="h6" component="h6">Who do you want to talk to?</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Button variant="contained" onClick={() => setPerson(parsedResult.names[0].isPersonA)}>
                        {parsedResult.names[0].name}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" onClick={() => setPerson(parsedResult.names[1].isPersonA)}>
                        {parsedResult.names[1].name}
                    </Button>
                </Grid>
            </>
        }
    </Grid>
}


export default Bot;