const createNewFeed = () => {
    let feed = {}
    feed.questions = []
    feed.answers = []
    return feed
}

const loadMessageFeed = (lines) => {
    const messageFeed = [];
    let previousPerson = null;

    lines.split('\n').forEach(line => {
        const currentPerson = line.substring(0, 3) == '<A>';
        const message = line.substring(4);

        if (previousPerson === null) {
            let feed = createNewFeed();
            feed.senderIsA = currentPerson;
            feed.questions.push(message);
            messageFeed.push(feed);

        } else if (previousPerson === currentPerson) {
            const lastMessage = messageFeed[messageFeed.length-1];
            const previousMessage = messageFeed.length-2 >= 0 && messageFeed[messageFeed.length-2];
            lastMessage.questions.push(message);
            previousMessage && previousMessage.answers.push(message);
        } else {
            const lastMessage = messageFeed[messageFeed.length-1];
            lastMessage.answers.push(message);

            let feed = createNewFeed();
            feed.senderIsA = currentPerson;
            feed.questions.push(message);
            messageFeed.push(feed);
        }
        
        previousPerson = currentPerson;
    });

    return messageFeed;
}

module.exports = loadMessageFeed;
